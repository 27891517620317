/* General */
/* * {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f8f9fa;
} */

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
a {
  text-decoration: none;
  color: inherit;
}

/* Contenedor principal de la app */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Hace que ocupe el 100% de la altura de la ventana */
}

/* Contenido principal */
.content {
  flex-grow: 1;
  flex: 1; /* Esto permite que el contenido principal se expanda y ocupe el espacio disponible */
}

.main {
  flex-grow: 1;
  padding: 10px;
}