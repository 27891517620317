.especialidades-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;

  }
  
  .especialidad-card {
    text-align: center;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .especialidad-card img {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .especialidad-card h3 {
    color: #0056b3;
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .especialidad-card a {
    text-decoration: none;
    color: #0056b3;
    font-weight: bold;
  }
  
  .especialidad-card:hover {
    transform: translateY(-5px);
  }
  