.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .contact-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .contact-map,
  .contact-form {
    flex: 1;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form button {
    margin-top: 15px;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-info {
    margin-top: 20px;
    text-align: left;
  }
  