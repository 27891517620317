/* Estilos generales */
h2 {

    color: #0044cc;
    margin-top: 0;
  }

.historia-section {
    display: flow;
    justify-content: center;
    padding: 50px;
  }
/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */
  
  /* Header */
  /* header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
  }
  
  header img {
    height: 50px;
    margin-right: 20px;
  }
  
  header h1 {
    font-size: 24px;
    color: #333;
  } */
  
  /* Sección de historia */
  /* section {
    padding: 20px;
    text-align: left;
  }
  
  h2 {
    font-size: 28px;
    color: #0044cc;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
  }
   */
  /* Sección de misión y visión */
  /* div {
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 22px;
    color: #0044cc;
    margin-top: 0;
  }
  
  .mision-vision-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  } */
  
  /* Avales */
  /* section img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  } */
  
  /* Sección de estadísticas */
  /* .estadisticas {
    background-color: #0044cc;
    color: #fff;
    padding: 30px 0;
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  
  .estadisticas div {
    flex: 1;
  }
  
  .estadisticas h3 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .estadisticas p {
    font-size: 18px;
  } */
  
  /* Responsive design */
  /* @media (max-width: 768px) {
    header {
      flex-direction: column;
    }
  
    .mision-vision-section {
      flex-direction: column;
      text-align: center;
    }
  
    .estadisticas {
      flex-direction: column;
    }
  
    .estadisticas div {
      margin-bottom: 20px;
    }
  } */
  