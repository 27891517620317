.home-header {
    text-align: center;
    padding: 2rem;
    background-color: #e0f7fa;
  }
  
  .services {
    margin: 2rem;
  }
  
  h3 {
    text-align: center;
  }

  .especialidades-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;

  }

  .seguros-container {
    text-align: center;
    padding: 20px;
  }
  
  .seguros-logos {
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 20px;

    
  }
  
  .seguros-logos img {
    max-width: 200px;
    height: auto;
  }
  
  .ver-mas {
    font-size: 1.1em;
    color: #0056b3;
    text-decoration: none;
    font-weight: bold;
  }