

.footer-content {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;
}

.footer-item {
  text-align: center;
  max-width: 200px;
}

.footer-item i {
  font-size: 2rem;
  color: #ffffff; /* Color verde similar al de la imagen */
  margin-bottom: 0.5rem;
}

.footer-item p {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: white;
}

.footer p {
  line-height: 1.2; /* Ajusta el valor según prefieras */
  margin: 5px 0; /* Reduce el margen superior e inferior */
}

.footer {
    padding: 2rem 0;
    background-color: #004d99;
    color: white;
    text-align: center;
    width: 100%;
}