
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #004a99;
  padding: 1rem;
  color: white;
}

.nav-menu {
  display: flex;
  gap: 1rem;
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #004a99;
    padding: 1rem;
    border-radius: 5px;
  }
  .nav-menu.open {
    display: flex;
  }
  .hamburger {
    display: block;
  }
}
